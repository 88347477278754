const message = ({ ...props }) => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect width="40" height="40" rx="12" fill="#BB6BD9" />
			<rect
				x="12"
				y="14"
				width="16"
				height="12"
				rx="2"
				stroke="#2E3A59"
				strokeWidth="2"
			/>
			<path
				d="M12 17L19.1056 20.5528C19.6686 20.8343 20.3314 20.8343 20.8944 20.5528L28 17"
				stroke="#2E3A59"
				strokeWidth="2"
			/>
		</svg>
	);
};

export default message;
