const Language = ({ ...props }) => {
	return (
		<svg
			width="55"
			height="55"
			viewBox="0 0 55 55"
			fill="#464A54"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.5716 0.428711C12.581 0.428711 0.428711 12.581 0.428711 27.5716C0.428711 42.5622 12.581 54.7144 27.5716 54.7144C42.5622 54.7144 54.7144 42.5622 54.7144 27.5716C54.7144 12.581 42.5622 0.428711 27.5716 0.428711ZM22.3748 11.4003C20.8508 14.8292 19.7744 19.5196 19.4984 24.8573H35.6448C35.3687 19.5196 34.2923 14.8292 32.7684 11.4003C31.8781 9.3973 30.8884 7.94436 29.9265 7.02893C28.9785 6.12681 28.1874 5.85728 27.5716 5.85728C26.9557 5.85728 26.1646 6.12681 25.2167 7.02893C24.2547 7.94436 23.265 9.3973 22.3748 11.4003ZM17.9239 8.11284C17.7476 8.46631 17.5776 8.82755 17.4141 9.19558C15.5418 13.4082 14.3433 18.8749 14.0633 24.8573H6.02528C6.94296 17.498 11.5433 11.2824 17.9239 8.11284ZM37.2192 8.11284C37.3956 8.46631 37.5655 8.82755 37.7291 9.19558C39.6013 13.4082 40.7998 18.8749 41.0798 24.8573H49.1179C48.2002 17.498 43.5998 11.2824 37.2192 8.11284ZM49.1179 30.2859H41.0798C40.7998 36.2682 39.6013 41.7349 37.7291 45.9476C37.5655 46.3156 37.3956 46.6768 37.2192 47.0303C43.5998 43.8607 48.2002 37.6451 49.1179 30.2859ZM27.5716 49.2859C28.1874 49.2859 28.9785 49.0163 29.9265 48.1142C30.8884 47.1988 31.8781 45.7458 32.7684 43.7428C34.2923 40.3139 35.3687 35.6236 35.6448 30.2859H19.4984C19.7744 35.6236 20.8508 40.3139 22.3748 43.7428C23.265 45.7458 24.2547 47.1988 25.2167 48.1142C26.1646 49.0163 26.9557 49.2859 27.5716 49.2859ZM17.9239 47.0303C17.7476 46.6768 17.5776 46.3156 17.4141 45.9476C15.5418 41.7349 14.3433 36.2682 14.0633 30.2859H6.02528C6.94297 37.6451 11.5433 43.8607 17.9239 47.0303Z"
			/>
		</svg>
	);
};

export default Language;
