import { useRef, useState } from 'react';
import { useTriggerOnScroll } from 'hooks';

type Args = {
	MAX: number;
} | undefined;

const useCardAnimation = (settings?: Args) => {
	const [counter, setCounter] = useState(0);
	const cardARef = useRef<HTMLDivElement>(null);
	const cardBRef = useRef<HTMLDivElement>(null);
	const MAX = settings?.MAX ?? 38;

	function animateCards() {
		cardARef.current!.style.transform = `translateY(${counter}%)`;
		cardBRef.current!.style.transform = `translateY(-${counter}%)`;
	}

	const triggers = {
		up: () => {
			if (!cardARef.current || counter < 0) return;
			setCounter(prev => prev - 0.5);
			animateCards();
		},
		down: () => {
			if (!cardARef.current || counter >= MAX) return;
			setCounter(prev => prev + 0.5);
			animateCards();
		},
	};

	const [triggerOnref] = useTriggerOnScroll(triggers);

	return [triggerOnref, cardARef, cardBRef] as const;
};

export default useCardAnimation;
