import { Suspense } from 'react';
import { ListInfoCards } from 'components';
import { useLanguage, useWindowDimensions } from 'hooks';
import { lazyLoad } from 'helpers';
import animateRotate from 'assets/animate_rotate.png';
import Features from './Features';
import './Section2.scss';

const Cube = lazyLoad('cube/Cube');

const Section2 = () => {
  const { section2 } = useLanguage();
  const { width } = useWindowDimensions();

  const indexedFeatures = (
    <div className='section2__top'>
      <div className='section2__top--left'>
        <Features features={section2.features} hasIndex />
      </div>
    </div>
  );

  // console.log(leftSide);

  const SectionBottom = () => (
    <div className='section2__bottom'>
      <p>{section2.paragraph.primary}</p>
      <br />
      <br />
      <p id='tools'>{section2.paragraph.secondary}</p>
    </div>
  );

  const ListCards = () => (
    <div className='listCards'>
      <ListInfoCards features={section2.features} />
    </div>
  );

  return (
    <section className='section2'>
      <Suspense>
        {width > 800 ? (
          <>
            <Cube leftSide={width <= 1024 ? <ListCards /> : indexedFeatures} />
            <SectionBottom />
          </>
        ) : (
          <>
            <div className='section2__top'>
              <div className='section2__top--left'>
                <ListCards />
                {/* <Features hasIndex features={section2.features} /> */}
              </div>
              <div className='section2__top--right'>
                {/* Canvas goes here */}
                <img
                  src={animateRotate}
                  className='section2__image'
                  alt='image'
                />
              </div>
            </div>

            <SectionBottom />
          </>
        )}
      </Suspense>
    </section>
  );
};

export default Section2;
