import { useEffect, useRef, useState } from 'react';

const useInView = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [inView, setInView] = useState(false);
	const [entry, setEntry] = useState<IntersectionObserverEntry>();

	useEffect(() => {
		const observer = new IntersectionObserver(entries => {
			const [observedEntry] = entries;
			setEntry(observedEntry);
			setInView(observedEntry.isIntersecting);
		});

		if (ref.current) {
			observer.observe(ref.current);
		}
	}, []);

	return [ref, entry, inView] as const;
};

export default useInView;
