import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
	ref: React.ForwardedRef<SVGSVGElement>
}

// @ts-ignore
const playstoreMobile: React.FC<IProps> = React.forwardRef(({ ...props }, ref) => {
	return (
		<svg
			width="233"
			height="79"
			viewBox="0 0 233 79"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{ ...props }
			ref={ref}
		>
			<path
				d="M226.484 1H6.51575C2.9172 1 0 3.72989 0 7.09738V71.9026C0 75.2701 2.9172 78 6.51575 78H226.484C230.083 78 233 75.2701 233 71.9026V7.09738C233 3.72989 230.083 1 226.484 1Z"
				fill="#00010C"
				stroke="#B4B6D0"
				strokeWidth="0.851602"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M171.296 37.9678H163.6V49.6939H171.296C171.296 49.6939 175.728 48.9374 175.728 43.8308C175.728 38.7244 171.296 37.9678 171.296 37.9678ZM170.317 40.8043H165.093V47.0971H170.317C170.317 47.0971 172.696 47.0971 172.696 43.9507C172.696 40.8043 170.317 40.8043 170.317 40.8043Z"
				fill="white"
			/>
			<path d="M165.635 37.9678H162.92V57.0436H165.635V37.9678Z" fill="white" />
			<path
				d="M180.024 37.9678H177.309V57.0436H180.024V37.9678Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M187.28 44.3721C183.481 44.3721 182.216 46.9906 182.216 46.9906L184.477 48.3322C184.477 48.3322 185.024 46.9906 187.496 46.9906C189.295 46.9906 189.896 48.3546 190.088 49.0969V49.6577C189.301 49.2729 187.594 48.6668 185.156 49.0305C181.851 49.5237 181.917 52.5237 181.928 52.9972C181.928 53.0167 181.928 53.0321 181.928 53.0426C181.928 53.3098 181.931 57.2719 186.256 57.0009C188.145 56.8825 189.341 56.2994 190.088 55.6802V57.0378H192.803V48.7577L192.805 48.7417C192.805 47.6409 192 44.3721 187.28 44.3721ZM184.482 53.1281C184.663 50.1788 189.894 51.5938 189.894 51.5938C189.894 51.5938 189.966 51.9057 189.966 52.5705C189.5 54.6612 184.3 56.0773 184.482 53.1281Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M199.257 53.0833L195.774 44.7803H193.059L197.9 56.3195L195.367 62.3572H198.082L205.428 44.8462H202.713L199.257 53.0833Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M151.097 44.3721C155.65 44.3721 156.84 49.2399 156.84 49.2399L148.252 52.8018C148.252 52.8018 148.807 54.7721 151.389 54.7852C153.411 54.7956 154.552 52.9868 154.552 52.9868L156.731 54.4676C156.731 54.4676 154.973 57.3213 151.372 57.3213C146.947 57.3213 144.998 53.4381 144.998 50.9162C144.998 48.4865 146.545 44.3721 151.097 44.3721ZM150.835 46.8858C152.492 46.5815 153.573 48.2314 153.573 48.2314L147.771 50.6079C147.771 50.6079 147.33 47.5293 150.835 46.8858Z"
				fill="white"
			/>
			<path
				d="M143.798 37.9678H140.926V57.0036H143.798V37.9678Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M136.328 45.9738V44.7858L138.984 44.8088V56.8198C138.984 59.8885 137.163 63.128 132.877 63.128C128.592 63.128 127.207 59.317 127.207 59.317L129.646 58.2875C129.646 58.2875 130.39 60.6363 133.004 60.6363C135.981 60.6363 136.328 57.9181 136.328 56.9877V55.6842C136.328 55.6842 135.298 57.3022 132.74 57.3022C129.966 57.3022 126.67 54.917 126.67 50.8507C126.67 46.7845 129.774 44.4587 132.772 44.3741C135.174 44.3062 136.328 45.9738 136.328 45.9738ZM136.473 50.8851C136.473 53.0811 134.903 54.8614 132.967 54.8614C131.03 54.8614 129.46 53.0811 129.46 50.8851C129.46 48.689 131.03 46.9088 132.967 46.9088C134.903 46.9088 136.473 48.689 136.473 50.8851Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M125.477 50.8442C125.477 54.4188 122.584 57.3164 119.014 57.3164C115.444 57.3164 112.551 54.4188 112.551 50.8442C112.551 47.2698 115.444 44.3721 119.014 44.3721C122.584 44.3721 125.477 47.2698 125.477 50.8442ZM122.605 50.8445C122.605 53.0172 120.978 54.7786 118.972 54.7786C116.965 54.7786 115.339 53.0172 115.339 50.8445C115.339 48.6717 116.965 46.9105 118.972 46.9105C120.978 46.9105 122.605 48.6717 122.605 50.8445Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M111.495 50.8442C111.495 54.4188 108.601 57.3164 105.032 57.3164C101.462 57.3164 98.5684 54.4188 98.5684 50.8442C98.5684 47.2698 101.462 44.3721 105.032 44.3721C108.601 44.3721 111.495 47.2698 111.495 50.8442ZM108.622 50.8445C108.622 53.0172 106.996 54.7786 104.989 54.7786C102.983 54.7786 101.356 53.0172 101.356 50.8445C101.356 48.6717 102.983 46.9105 104.989 46.9105C106.996 46.9105 108.622 48.6717 108.622 50.8445Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M88.3418 46.4238H96.8751L96.7906 49.1312H88.3418V46.4238Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M88.2149 57.3372C93.8367 57.3879 98.5255 53.4181 97.5878 46.5239C97.5878 46.4905 97.5877 46.457 97.5875 46.4237H95.095C95.1152 46.7114 95.1224 47.0076 95.1155 47.3117C95.1216 52.2357 92.289 54.5453 88.2995 54.5453C84.31 54.5453 81.0759 51.3066 81.0759 47.3117C81.0759 43.3167 84.31 40.0781 88.2995 40.0781C90.2955 40.0781 92.0253 40.7617 93.2433 42.0089L95.1959 40.0535C93.5349 38.2973 91.1052 37.2861 88.2149 37.2861C82.6855 37.2861 78.2031 41.7748 78.2031 47.3117C78.2031 52.8487 82.6855 57.3293 88.2149 57.3372Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M61.8515 36.1219C64.7341 37.7673 64.7341 41.9371 61.8515 43.5827L38.189 57.0905C35.3384 58.7179 31.7988 56.6515 31.7988 53.3601V26.3449C31.7988 23.0534 35.3384 20.9872 38.189 22.6144L61.8515 36.1219Z"
				fill="url(#paint0_linear_671_902)"
			/>
			<mask
				id="mask0_671_902"
				// style="mask-type:alpha"
				maskUnits="userSpaceOnUse"
				x="31"
				y="22"
				width="34"
				height="36"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M61.8515 36.1219C64.7341 37.7673 64.7341 41.9371 61.8515 43.5827L38.189 57.0905C35.3384 58.7179 31.7988 56.6515 31.7988 53.3601V26.3449C31.7988 23.0534 35.3384 20.9872 38.189 22.6144L61.8515 36.1219Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_671_902)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M31.7725 20.6123L50.7504 39.5432L31.5273 59.2222L31.7725 20.6123Z"
					fill="url(#paint1_linear_671_902)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M27.8691 16.7158L50.7474 39.5493L57.5492 33.0368L27.8691 16.7158Z"
					fill="url(#paint2_linear_671_902)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M50.7497 39.5439L61.1627 50.078L30.0547 60.6121L50.7497 39.5439Z"
					fill="url(#paint3_linear_671_902)"
				/>
			</g>
			<path
				d="M85.9112 26.0431V25.112H82.2969V26.3031H84.3932L84.3872 26.4482C84.3631 27.6695 83.4535 28.5037 82.1403 28.5037C80.574 28.5037 79.5982 27.2765 79.5982 25.2692C79.5982 23.3044 80.5319 22.1375 82.0981 22.1375C83.1643 22.1375 83.9716 22.73 84.2667 23.7336H85.8449C85.5618 21.9863 84.0679 20.8135 82.092 20.8135C79.58 20.8135 78.0078 22.5183 78.0078 25.2874C78.0078 28.0986 79.5861 29.8277 82.1221 29.8277C84.5016 29.8277 85.9112 28.4071 85.9112 26.0431ZM93.4255 28.3647H89.3533V25.8738H93.2026V24.6344H89.3533V22.2765H93.4255V20.9586H87.7991V29.6828H93.4255V28.3647ZM99.0663 29.6828V22.2765H101.663V20.9586H94.9158V22.2765H97.5181V29.6828H99.0663ZM107.987 29.6828V20.9586H106.432V29.6828H107.987ZM113.808 29.6828V22.2765H116.405V20.9586H109.658V22.2765H112.26V29.6828H113.808ZM125.005 20.8135C122.469 20.8135 120.879 22.5485 120.879 25.3236C120.879 28.0986 122.469 29.8277 125.005 29.8277C127.536 29.8277 129.126 28.0986 129.126 25.3236C129.126 22.5485 127.536 20.8135 125.005 20.8135ZM125.005 22.1436C126.554 22.1436 127.542 23.3768 127.542 25.3236C127.542 27.2644 126.554 28.4976 125.005 28.4976C123.451 28.4976 122.469 27.2644 122.469 25.3236C122.469 23.3768 123.451 22.1436 125.005 22.1436ZM132.484 29.6828V23.5644H132.538L136.857 29.6828H138.194V20.9586H136.7V27.0709H136.646L132.333 20.9586H130.984V29.6828H132.484Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_671_902"
					x1="66.204"
					y1="32.3587"
					x2="52.816"
					y2="32.3587"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FBDA09" />
					<stop offset="1" stopColor="#FFBC00" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_671_902"
					x1="33.3913"
					y1="56.5693"
					x2="47.4836"
					y2="54.606"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#01E2FF" />
					<stop offset="1" stopColor="#01C4FF" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_671_902"
					x1="24.4623"
					y1="28.5598"
					x2="38.4866"
					y2="46.7838"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#28D781" />
					<stop offset="1" stopColor="#02F076" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_671_902"
					x1="41.7549"
					y1="36.4343"
					x2="29.761"
					y2="56.7222"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F2414E" />
					<stop offset="1" stopColor="#E02554" />
				</linearGradient>
			</defs>
		</svg>
	);
});

playstoreMobile.displayName = 'playstoreMobile';
export default playstoreMobile;
