const arrowR1 = ({ ...props }) => {
	return (
		<svg
			width="11"
			height="11"
			viewBox="0 0 11 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.84055 1.6709L10.6758 1.6709C10.6758 1.20962 10.3018 0.835671 9.84055 0.835671V1.6709ZM3.57635 2.50613H9.84055V0.835671H3.57635V2.50613ZM9.00533 1.6709L9.00533 7.9351H10.6758L10.6758 1.6709L9.00533 1.6709ZM9.24996 1.0803L0.897686 9.43258L2.07888 10.6138L10.4311 2.26149L9.24996 1.0803Z"
				fill="#B485DA"
			/>
		</svg>
	);
};

export default arrowR1;
