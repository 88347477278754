const Logo = ({ ...props }) => {
	return (
		<svg
			width="82"
			height="20"
			viewBox="0 0 82 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{ ...props }
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.6433 6.38903C15.6433 5.67125 15.6433 4.95347 15.6433 4.2326H8.55793V6.38903H15.6433ZM10.7821 12.6796H12.3933V14.2477H10.7821V12.6796ZM0 0.00292969L2.18723 2.19016V17.7904C3.4626 17.7904 4.21428 17.624 5.18467 16.6567C5.96714 15.8742 6.41383 14.8176 6.41383 13.7086V6.38903H8.57025C8.57025 7.10681 8.57025 9.80543 8.57025 10.5232H10.7821V12.6796H8.57025V13.6285C8.57025 13.8379 8.57025 14.0443 8.55177 14.2538C8.42239 15.7695 7.74157 17.1804 6.65411 18.2432C4.55622 20.2887 2.64624 19.9838 0.00308127 19.9838V0.00292969H0Z"
				fill="url(#paint0_linear_723_59)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.23242 0.00307892L2.18741 2.19031H45.8674V17.7905H8.9894C8.64437 18.3604 8.2254 18.8841 7.74175 19.3555C7.51378 19.5773 7.27349 19.7867 7.02397 19.9777H48.0577V0H1.23242V0.00307892Z"
				fill="url(#paint1_linear_723_59)"
			/>
			<path
				d="M16.8301 15.7692V4.23535H18.854V15.7692H16.8301Z"
				fill="url(#paint2_linear_723_59)"
			/>
			<path
				d="M26.9918 15.7692L24.4318 11.4625H22.4263V15.7692H20.4023V4.23535H25.4669C27.7496 4.23535 29.2375 5.72328 29.2375 7.8489C29.2375 9.90675 27.8882 11.0127 26.505 11.2376L29.3238 15.7692H26.9918ZM27.1643 7.8489C27.1643 6.74297 26.3356 6.01594 25.1927 6.01594H22.4263V9.68187H25.1927C26.3356 9.68187 27.1643 8.95484 27.1643 7.8489Z"
				fill="url(#paint3_linear_723_59)"
			/>
			<path
				d="M40.8952 15.7692V7.21122L36.9273 13.7514L32.9595 7.21122V15.7692H30.9355V4.23535H33.7882L36.9181 9.90983L40.0634 4.23535H42.916V15.7692H40.8952V15.7692Z"
				fill="url(#paint4_linear_723_59)"
			/>
			<path
				d="M79.3758 11.978C80.796 11.3681 81.6 10.0835 81.6 8.33677C81.6 5.78295 79.8965 4.2334 77.1486 4.2334H72.848V15.7672H74.7888V12.4063H77.1486C77.2841 12.4063 77.4166 12.4063 77.5521 12.3909L79.687 15.7672H81.7787L79.3758 11.978ZM77.0623 10.6442H74.7919V6.04788H77.0623C78.7659 6.04788 79.6469 6.88889 79.6469 8.33677C79.6438 9.78774 78.7628 10.6442 77.0623 10.6442ZM54.7248 4.23648H50.1562V15.7703H54.7248C58.1135 15.7703 60.4301 13.4629 60.4301 10.0034C60.4301 6.54386 58.1135 4.23648 54.7248 4.23648ZM54.6355 13.9558H52.097V6.04788H54.6355C56.9644 6.04788 58.4739 7.61283 58.4739 10.0034C58.4739 12.3909 56.9644 13.9558 54.6355 13.9558ZM64.5057 13.9743H70.4051V15.7703H62.5649V4.23648H70.1956V6.03248H64.5057V9.02991H69.5518V10.792H64.5057V13.9743Z"
				fill="url(#paint5_linear_723_59)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_723_59"
					x1="-0.354732"
					y1="10.0091"
					x2="76.1139"
					y2="9.54983"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FE9172" />
					<stop offset="0.0727" stopColor="#F99079" />
					<stop offset="0.1812" stopColor="#EA8E8E" />
					<stop offset="0.312" stopColor="#D289AF" />
					<stop offset="0.4591" stopColor="#B184DD" />
					<stop offset="0.4951" stopColor="#A882E9" />
					<stop offset="0.6464" stopColor="#7D92F1" />
					<stop offset="0.7978" stopColor="#599FF8" />
					<stop offset="0.921" stopColor="#43A7FC" />
					<stop offset="1" stopColor="#3BAAFE" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_723_59"
					x1="-0.35366"
					y1="10.144"
					x2="76.099"
					y2="9.68479"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FE9172" />
					<stop offset="0.0727" stopColor="#F99079" />
					<stop offset="0.1812" stopColor="#EA8E8E" />
					<stop offset="0.312" stopColor="#D289AF" />
					<stop offset="0.4591" stopColor="#B184DD" />
					<stop offset="0.4951" stopColor="#A882E9" />
					<stop offset="0.6464" stopColor="#7D92F1" />
					<stop offset="0.7978" stopColor="#599FF8" />
					<stop offset="0.921" stopColor="#43A7FC" />
					<stop offset="1" stopColor="#3BAAFE" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_723_59"
					x1="-0.353363"
					y1="10.1105"
					x2="76.0994"
					y2="9.65137"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FE9172" />
					<stop offset="0.0727" stopColor="#F99079" />
					<stop offset="0.1812" stopColor="#EA8E8E" />
					<stop offset="0.312" stopColor="#D289AF" />
					<stop offset="0.4591" stopColor="#B184DD" />
					<stop offset="0.4951" stopColor="#A882E9" />
					<stop offset="0.6464" stopColor="#7D92F1" />
					<stop offset="0.7978" stopColor="#599FF8" />
					<stop offset="0.921" stopColor="#43A7FC" />
					<stop offset="1" stopColor="#3BAAFE" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_723_59"
					x1="-0.354331"
					y1="10.1527"
					x2="76.0983"
					y2="9.69354"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FE9172" />
					<stop offset="0.0727" stopColor="#F99079" />
					<stop offset="0.1812" stopColor="#EA8E8E" />
					<stop offset="0.312" stopColor="#D289AF" />
					<stop offset="0.4591" stopColor="#B184DD" />
					<stop offset="0.4951" stopColor="#A882E9" />
					<stop offset="0.6464" stopColor="#7D92F1" />
					<stop offset="0.7978" stopColor="#599FF8" />
					<stop offset="0.921" stopColor="#43A7FC" />
					<stop offset="1" stopColor="#3BAAFE" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_723_59"
					x1="-0.35326"
					y1="10.2252"
					x2="76.0993"
					y2="9.766"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FE9172" />
					<stop offset="0.0727" stopColor="#F99079" />
					<stop offset="0.1812" stopColor="#EA8E8E" />
					<stop offset="0.312" stopColor="#D289AF" />
					<stop offset="0.4591" stopColor="#B184DD" />
					<stop offset="0.4951" stopColor="#A882E9" />
					<stop offset="0.6464" stopColor="#7D92F1" />
					<stop offset="0.7978" stopColor="#599FF8" />
					<stop offset="0.921" stopColor="#43A7FC" />
					<stop offset="1" stopColor="#3BAAFE" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_723_59"
					x1="83.1702"
					y1="9.73568"
					x2="47.6267"
					y2="10.2867"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#81ECAA" />
					<stop offset="0.5343" stopColor="#8ACBEB" />
					<stop offset="1" stopColor="#A882E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Logo;
