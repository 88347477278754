const closeRound = ({ ...props }) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 23 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6745 20.165c4.7855.0 8.665-3.8795 8.665-8.6651.0-4.78558-3.8795-8.66506-8.665-8.66506-4.78562.0-8.6651 3.87948-8.6651 8.66506.0 4.7856 3.87948 8.6651 8.6651 8.6651zm0 1.6602c5.7025.0 10.3253-4.6228 10.3253-10.3253.0-5.70253-4.6228-10.32534-10.3253-10.32534-5.70257.0-10.32538 4.62281-10.32538 10.32534.0 5.7025 4.62281 10.3253 10.32538 10.3253z"
				fill="#f8f9fc"
			></path>
			<path
				d="M19.8929 11.4999c0 4.5389-3.6795 8.2185-8.2184 8.2185v.8931c5.0322.0 9.1116-4.0794 9.1116-9.1116h-.8932zM11.6745 3.28141c4.5389.0 8.2184 3.67954 8.2184 8.21849h.8932c0-5.03222-4.0794-9.11164-9.1116-9.11164v.89315zM3.45597 11.4999c0-4.53895 3.67954-8.21849 8.21853-8.21849V2.38826c-5.03226.0-9.11168 4.07942-9.11168 9.11164h.89315zm8.21853 8.2185c-4.53899.0-8.21853-3.6796-8.21853-8.2185H2.56282c0 5.0322 4.07942 9.1116 9.11168 9.1116v-.8931zm9.8787-8.2185c0 5.4559-4.4229 9.8788-9.8787 9.8788v.8931c5.9491.0 10.7719-4.8227 10.7719-10.7719h-.8932zM11.6745 1.62114c5.4558.0 9.8787 4.42287 9.8787 9.87876h.8932c0-5.94917-4.8228-10.771915-10.7719-10.771915V1.62114zM1.7957 11.4999c0-5.45589 4.42287-9.87876 9.8788-9.87876V.727985C5.7253.727985.902545 5.55073.902545 11.4999H1.7957zm9.8788 9.8788c-5.45593.0-9.8788-4.4229-9.8788-9.8788H.902545c0 5.9492 4.822755 10.7719 10.771955 10.7719v-.8931z"
				fill="#f8f9fc"
			></path>
			<path
				d="M8.4861 13.915C8.22265 14.1785 8.20948 14.6746 8.49488 14.96 8.78467 15.2453 9.27643 15.2365 9.54427 14.9731l2.23933-2.2435 2.2392 2.2391C14.2995 15.2453 14.7824 15.2453 15.0678 14.9556 15.3532 14.6702 15.3576 14.1916 15.0766 13.9106l-2.2349-2.2347 2.2349-2.23905C15.3576 9.15586 15.3532 8.67731 15.0678 8.39194 14.7824 8.10656 14.2995 8.10217 14.0228 8.37876L11.7836 10.6179 9.54427 8.37437C9.27643 8.11095 8.78028 8.10217 8.49488 8.38755 8.20948 8.67292 8.22265 9.16903 8.4861 9.43246l2.2437 2.24344L8.4861 13.915z"
				fill="#f8f9fc"
			></path>
		</svg>
	);
};

export default closeRound;
