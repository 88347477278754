import React, { useRef } from 'react';
import { ArrowR1, Chat, Logo } from 'assets';
import { Modal, TermsCondition } from 'components';
import { useLanguage, useWindowDimensions } from 'hooks';
import './Footer.scss';

const Footer: React.FC = () => {
	const { footer } = useLanguage();
	const termsRef = useRef<HTMLLIElement>(null);
	const { width } = useWindowDimensions();
	const MOBILE_WIDTH = 1100;
	const isOnMobile = width <= MOBILE_WIDTH;

	const FooterButton = () => (
		<>
			<a href="mailto:info@firmder.com">
				<button className="button__secondary">{footer.investors}</button>
			</a>
		</>
	);

	const IMessage = () => (
		<a
			href="https://bcrw.apple.com/sms:open?service=iMessage&recipient=urn:biz:98831619-cc1b-43d1-bfb3-e3fe431f99cc"
			rel="nofollow noopener noreferrer"
		>
			<span>
				<Chat className="footer__img-chat" />
			</span>
		</a>
	);

	return (
		<footer className="footer">
			<div className="footer__head">
				<Logo />
				{/* {footerLogo} */}
				{!isOnMobile && <IMessage />}
			</div>
			<nav className="footer__links">
				<ul>
					<div className="footer__links--pack">
						<li>
							<a
								href="https://www.linkedin.com/company/firmder/"
								target="_blank"
								rel="noopener noreferrer"
							>
								linkedIn
							</a>{' '}
							<ArrowR1 />
						</li>
						<li>
							<a
								href="https://www.facebook.com/firmder"
								target="_blank"
								rel="noopener noreferrer"
							>
								facebook
							</a>{' '}
							<ArrowR1 />
						</li>

						<li>
							<a
								href="https://www.youtube.com/@firmder"
								target="_blank"
								rel="noopener noreferrer"
							>
								youtube
							</a>{' '}
							<ArrowR1 />
						</li>
					</div>

					<div className="footer__links--pack-b">
						<li>
							<a
								href="https://www.instagram.com/firmder"
								target="_blank"
								rel="noopener noreferrer"
							>
								instagram
							</a>{' '}
							<ArrowR1 />
						</li>
						<li>
							<a
								href="https://www.twitter.com/firmder"
								target="_blank"
								rel="noopener noreferrer"
							>
								twitter
							</a>{' '}
							<ArrowR1 />
						</li>
					</div>

					{/* <div className="footer__links--pack">
						
					</div> */}
				</ul>
				{!isOnMobile && <FooterButton />}
			</nav>
			<div className="footer__actions">
				<IMessage />
				{isOnMobile && <FooterButton />}
			</div>
			<div className="footer__sitemaps">
				<ul>
					<li>
						<a href="#tools">{footer.tools}</a>
					</li>
					<li>
						<a href="#services">{footer.services}</a>
					</li>
					<li ref={termsRef}>
						<span>{footer.termsConditions}</span>
					</li>
				</ul>
				<div className="footer__CTA">
					{isOnMobile && (
						<li ref={termsRef}>
							<span>{footer.termsConditions}</span>
						</li>
					)}
					<a href="mailto:info@firmder.com">info@firmder.com</a>
				</div>
			</div>
			<Modal listenOn={termsRef} Body={<TermsCondition />} />
		</footer>
	);
};

export default Footer;
