interface IProps extends React.SVGProps<SVGSVGElement> {
	primaryColor?: string;
	secondaryColor?: string;
}

const Spinner: React.FC<IProps> = ({
	primaryColor = '#e15c64',
	secondaryColor = '#f47e5f',
	...props
}) => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			viewBox="0 0 100 100"
			xmlSpace="preserve"
			{...props}
		>
			<path
				d="M10.1 46.6c.9-8.7 4.4-17.2 10.4-23.6 3-3.2 6.4-5.9 10.2-8.1 3.8-2.1 8-3.5 12.3-4.3 8.6-1.6 17.7-.1 25.4 3.9 3.9 2 7.4 4.7 10.4 7.8 3 3.2 5.5 6.8 7.4 10.7-2.4-3.7-5.3-7-8.6-9.6-3.3-2.6-6.9-4.7-10.7-6-3.8-1.3-7.7-2-11.6-2.1-1 0-1.9 0-2.9.1l-2.8.3c-1.9.4-3.7.7-5.4 1.4-3.5 1.2-6.7 2.9-9.5 5-2.8 2.2-5.1 4.7-6.9 7.5-1.8 2.8-3.1 5.8-3.8 8.8-.7 3.1-.9 6.1-.7 9.1.3 3.7-2.5 6.9-6.1 7.2-3.7.3-6.9-2.5-7.2-6.1v-1.1l.1-.9z"
				fill={primaryColor}
			/>
			<path
				d="M88.9 52.4C88 61.1 84.5 69.6 78.5 76c-3 3.2-6.4 5.9-10.2 8.1-3.8 2.1-8 3.5-12.3 4.3-8.6 1.6-17.7.1-25.4-3.9-3.9-2-7.4-4.7-10.4-7.8-3-3.2-5.5-6.8-7.4-10.7 2.4 3.7 5.3 7 8.6 9.6 3.3 2.6 6.9 4.7 10.7 6 3.8 1.3 7.7 2 11.6 2.1 1 0 1.9 0 2.9-.1l2.8-.3c1.9-.4 3.7-.7 5.4-1.4 3.5-1.2 6.7-2.9 9.5-5 2.8-2.2 5.1-4.7 6.9-7.5 1.8-2.8 3.1-5.8 3.8-8.8.7-3.1.9-6.1.7-9.1-.3-3.7 2.5-6.9 6.1-7.2 3.7-.3 6.9 2.5 7.2 6.1v1.1l-.1.9z"
				fill={secondaryColor}
			/>
		</svg>
	);
};

export default Spinner;
