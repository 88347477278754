import { useCallback, useEffect, useRef, useState } from 'react';

const useNavigationEffect = () => {
	const [scrollState, setScrollState] = useState<0 | 1>(1);
	const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('up');

	const lastScrollTopRef = useRef(0);
	const scrollFunc = () => {
		var st = window.pageYOffset || document.documentElement.scrollTop;
		if (st > lastScrollTopRef.current && st >= 39) {
			setScrollDirection('down');
		} else {
			setScrollDirection('up');
		}
		lastScrollTopRef.current = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	};

	const CBscrollFunc = useCallback(scrollFunc, []);

	useEffect(() => {
		window.addEventListener('scroll', CBscrollFunc);

		if (scrollDirection === 'down') {
			setScrollState(0);
		} else {
			setScrollState(1);
		}
		return () => {
			window.removeEventListener('scroll', CBscrollFunc);
		};
	}, [scrollDirection, CBscrollFunc]);

	return [scrollState];
};

export default useNavigationEffect;
