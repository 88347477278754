import { BulletMap } from 'helpers';
import { useGlobalContext } from 'utilities';
import './TermsCondition.scss';

const TermsCondition = () => {
	const data = useGlobalContext();
	const text = data[data.language];

	const {
		mainHeading,
		company,
		lastUpdated,
		privacyPolicy,
		interpretationDefinitions,
		usage,
		access,
		trackingCookies,
		statistics,
		purpose,
		socialMedia,
		disclosureData,
		security,
		analytics,
		advertising,
		payments,
		measureAnalyze,
		facebook,
		invisibleRecaptcha,
		exercisingGDPR,
		sourcesOfPersonalInfo,
		ourAffiliates,
		exercisingYourCCPA,
		optOut,
		mobileDevices,
		contactUs,
	} = text.termsAndConditions;

	return (
		<div className='termsCondition'>
			<div className='termsCondition__head'>
				<h3>{mainHeading[0]}</h3>
				<h1>{mainHeading[1]}</h1>
			</div>

			<div className='termsCondition__container'>
				<h3>{company.title}</h3>
				<p><b>{company.director.header}:</b></p>
				<p>{company.director.text}</p>
				<p><b>{company.registration.header}:</b></p>
				<p>{company.registration.text}</p>
				<p><b>{company.vat.header}:</b></p>
				<p>{company.vat.text}</p>
				<p><b>{company.number.header}:</b></p>
				<p>{company.number.text}</p>
				<p><b>{company.id.header}:</b></p>
				<p>{company.id.text}</p>
				<p><b>{company.snumber.header}:</b></p>
				<p>{company.snumber.text}</p>
				<p><b>{company.address.header}:</b></p>
				<p>{company.address.text[0]}</p>
				<p>{company.address.text[1]}</p>
				<p>{company.address.text[2]}</p>
				<p>{company.address.text[3]}</p>
				<p>{company.address.text[4]}</p>
			</div>

			<div className='termsCondition__container'>
				<p>{lastUpdated}</p>
				<h3>{privacyPolicy.title}</h3>
				<p>{privacyPolicy.text}</p>
			</div>

			<div className='termsCondition__container'>
				<h3>{interpretationDefinitions.title}</h3>
				<p>{interpretationDefinitions.text}</p>
			</div>

			<div className='termsCondition__container'>
				<h3>{interpretationDefinitions.definitions.title}</h3>
				<p>{interpretationDefinitions.definitions.text}</p>
				<div>
					<BulletMap bullets={interpretationDefinitions.definitions.bullets} />
				</div>
			</div>

			<div className='termsCondition__container'>
				<BulletMap bullets={usage.bullets} />
			</div>

			<div className='termsCondition__container'>
				<p>{access.text}</p>
				<BulletMap bullets={access.bullets} />
				<p>{access.more}</p>
			</div>

			<div className='termsCondition__container'>
				<h3>{trackingCookies.title}</h3>
				<p>{trackingCookies.text}</p>
				<BulletMap bullets={trackingCookies.bullets} />
			</div>

			<div className='termsCondition__container'>
				<p>{statistics.text}</p>
				<p>{statistics.text2}</p>
				<p>{statistics.text3}</p>
				<BulletMap bullets={statistics.bullets} />
			</div>

			<div className='termsCondition__container'>
				<p>{purpose.text}</p>
				<BulletMap bullets={purpose.bullets} />
			</div>

			<div className='termsCondition__container'>
				<p>{socialMedia.text}</p>
			</div>

			<div className='termsCondition__container'>
				<h3>{disclosureData.title}</h3>
				<p>{disclosureData.text}</p>
				<BulletMap bullets={disclosureData.bullets} />
			</div>

			<div className='termsCondition__container'>
				<p>{security.text}</p>
			</div>

			<div className='termsCondition__container'>
				<h3>{analytics.title}</h3>
				<p>{analytics.text}</p>
				<BulletMap bullets={analytics.bullets} />
			</div>

			<div className='termsCondition__container'>
				<h3>{advertising.title}</h3>
				<p>{advertising.text}</p>
				<BulletMap bullets={advertising.bullets} />
			</div>

			<div className='termsCondition__container'>
				<h3>{payments.title}</h3>
				<p>{payments.text}</p>
				<BulletMap bullets={payments.bullets} />
			</div>

			<div className='termsCondition__container'>
				<p>{measureAnalyze.text}</p>
				<BulletMap bullets={measureAnalyze.bullets} />
			</div>

			<div className='termsCondition__container'>
				<h3>{facebook.title}</h3>
				<p>{facebook.text}</p>
			</div>

			<div className='termsCondition__container'>
				<h3>{invisibleRecaptcha.title}</h3>
				<p>{invisibleRecaptcha.text}</p>
				<BulletMap bullets={invisibleRecaptcha.bullets} />
			</div>

			<div className='termsCondition__container'>
				<h3>{exercisingGDPR.title}</h3>
				<p>{exercisingGDPR.text}</p>
				<BulletMap bullets={exercisingGDPR.bullets} />
			</div>

			<div className='termsCondition__container'>
				<h3>{sourcesOfPersonalInfo.title}</h3>
				<p>{sourcesOfPersonalInfo.text}</p>
				<BulletMap bullets={sourcesOfPersonalInfo.bullets} />
			</div>

			<div className='termsCondition__container'>
				<h3>{ourAffiliates.title}</h3>
				<BulletMap bullets={ourAffiliates.bullets} />
			</div>

			<div className='termsCondition__container'>
				<h3>{exercisingYourCCPA.title}</h3>
				<p>{exercisingYourCCPA.text}</p>
				<BulletMap bullets={exercisingYourCCPA.bullets} />
			</div>

			<div className='termsCondition__container'>
				<p>{optOut.text}</p>
			</div>

			<div className='termsCondition__container'>
				<p>{mobileDevices.text}</p>
				<BulletMap bullets={mobileDevices.bullets} />
			</div>

			<div className='termsCondition__container'>
				<p>{contactUs.text}</p>
				<BulletMap bullets={contactUs.bullet} />
			</div>
		</div>
	);
};

export default TermsCondition;

// purpose remove bullet
