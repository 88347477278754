import React, { useLayoutEffect } from 'react';
import { to2Digits } from 'helpers';
import { HeadingParagraph } from 'Types';
import { useInView } from 'hooks';

interface IProps {
	features: HeadingParagraph[];
	hasIndex?: boolean;
}

const Features: React.FC<IProps> = ({ features, hasIndex }) => {
	const [ref1, entry1, inView1] = useInView();
	const [ref2, entry2, inView2] = useInView();
	const [ref3, entry3, inView3] = useInView();

	const allRefs = [
		{ ref: ref1, entry: entry1, inView: inView1 },
		{ ref: ref2, entry: entry2, inView: inView2 },
		{ ref: ref3, entry: entry3, inView: inView3 }
	];

	function switchClasses() {
		allRefs
			.filter(ref => ref.inView)
			.map(({ entry, inView }) => {
				const target = entry?.target as Element;

				if (inView) {
					target.classList.add('visible');
					target.classList.remove('opaque');
				}
			});
		
		
			allRefs
			.filter(ref => !ref.inView)
			.map(({ entry, inView }) => {
				const target = entry?.target as Element;

				if (target && !inView) {
					target.classList.remove('visible');
					target.classList.add('opaque');
				}
			});
	}

	useLayoutEffect(() => {
		switchClasses();
	});

	return (
		<div className="features">
			{features.map((el, idx) => (
				<div key={idx} ref={allRefs[idx].ref} className="features__list">
					{hasIndex && (
						<span className="features__index">
							<span className="features__border" />
							<span className="features__count">{to2Digits(idx + 1)}</span>
						</span>
					)}
					<div className="features__text">
						<h3>{el.heading}</h3>
						{el.paragraph && <p>{el.paragraph}</p>}
					</div>
				</div>
			))}
		</div>
	);
};

export default Features;
