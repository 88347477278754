import { Card, Carousel, CarouselItem } from 'components';
import { useCardAnimation, useLanguage, useWindowDimensions } from 'hooks';
import './Section5.scss';

const Section5 = () => {
	const { section5 } = useLanguage();
	const [triggerOnRef, cardARef, cardBRef] = useCardAnimation({ MAX: 7 });
	const { width } = useWindowDimensions();
	return (
		<section className="section5">
			<div className="section5__header">
				<h4>{section5.heading}</h4>
				<p>{section5.paragraph}</p>
			</div>
			<div className="section5__cards" ref={triggerOnRef}>
				{width >= 980 ? (
					<Card
						features={section5.cards}
						hasUpdateMessage
						allRef={[cardARef, cardBRef]}
					/>
				) : (
					<Carousel>
						<CarouselItem>
							<Card features={section5.cards.slice(0, 1)} hasUpdateMessage />
						</CarouselItem>

						<CarouselItem>
							<Card features={section5.cards.slice(1)} hasUpdateMessage />
						</CarouselItem>
					</Carousel>
				)}
			</div>
		</section>
	);
};

export default Section5;
