import { useKineograpgh } from 'hooks';

const Padlock = () => {
	const kineographOptions = {
		scrollHeight: window.innerWidth >= 1100 ? 4800 : 2800,
		width: 1920,
		height: 1080,
		numFrames: 60,
		framesURL: '/assets/safe__lock',
		startOn: 4500,
		useHeightOnCanvas: false,
	};
	const [Canvas] = useKineograpgh(kineographOptions);
	return <main className="mainc">{Canvas}</main>;
};

export default Padlock;
