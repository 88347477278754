
import { IText } from 'Types/ILanguage';
import termsAndConditions from 'utilities/termsCondition';


export const text: IText = {
	en: {
		header: {
			signIn: 'Sign in',
			startBusiness: 'Start my buisniess',
		},
		hero: {
			heading: 'Starting a company has never been that simple.',
			paragraph: `The easiest way to start and run a business for anyone. 
			Launch your company, get the access to the growth drivers by a click, meet requirements and manage everything that your business needs, - and everything online no matter where you are.`,
			button: 'Start your business',
		},
		section1: {
			heading:
				'Start your business within seconds no matter where in the world you are.',
			paragraph:
				'Our company believes in the idea where an entrepreneur creates everything that surrounds people. Regardless of what space you are in, everything you see around you - was once already created by an entrepreneur, a person who once started to act and dared to risk without fear of possible problems or obstacles.',
		},
		section2: {
			features: [
				{
					heading: 'Launch companies in 2 clicks',
					paragraph:
						'FIRMDER is a service supporting entrepreneurs by fast and, the key is, simple company registration.',
				},
				{
					heading: 'Access all the services you need',
					paragraph: "We provide our customers with tax accounting services, office rentals, mail forwarding services, local phone and company secretary services, as well as development of company websites and other related services for the implementation of our customers' business.",
				},
				{
					heading: 'Manage all the processes in one panel',
					paragraph: '',
				}
			],
			paragraph: {
				primary: 'Unfortunately, nowadays most countries offer a "manual" service, that is, everything must be solved separately. This means, for example, going to lawyers, dealing with issues through various public institutions, filling out a lot of documents and so on. And the whole thing has to be done, just to start your own business and understand how to run it.',

				secondary: 'But we plan to digitize, that is, simplify the process of starting a business.',
			},
		},
		section3: {
			heading: 'Comfortable business managing tools',
			features: [
				{
					heading: 'Fast',
					paragraph:
						'Fast access to any infrastructure of your company no matter where in the world you are. Accounting services, mail forwarding services, local phone and company secretary services.',
				},
				{
					heading: 'Advantageous',
					paragraph: `Fast audit of your business due to our marketplace of ready/made companies (the launch is coming soon).
					`,
				},
				{
					heading: 'Always nearby',
					paragraph: 'Our chat experts are always ready to answer all your questions. Write to your own expert accountant for tax support. Or to a manager for alterations or management of your company.',
				},
				{
					heading: 'Groundbreaking',
					paragraph: `FIRMDER Bank is a disruptive innovation that lets you open a corporate account in FIRMDER ecosystem. Our Machine Learning (ML) technologies will choose which transaction we recommend to strike off charge (the launch is coming soon).
					We'll make your accountant happier.
					`,
				}
			],
		},
		section4: {
			heading: 'Complementary services',
			paragraph: 'FIRMDER app and website enable using complementary services',
			cards: [
				{
					heading: 'Virtual office',
					paragraph: 'Scanning of emails coming to your company address. The first version of the app allows not only creating a company, but also renting an office (including scanning letters addressed to the company address).',
				},
				{
					heading: 'Accounting',
					paragraph: 'In solving various tasks, we use our already five-year experience in the field of both management and establishment of companies worldwide and accounting services for numerous customers. Thus we are aware of all the problems and issues an entrepreneur may encounter and ready to solve them using our Firmder service.',
				},
				{
					heading: 'Trade mark registration',
					paragraph: 'With FIRMDER TM service your business will always be protected. We will register your company trade mark on EU entire territory.',
				}
			],
		},
		section5: {
			heading: 'What else are we planning',
			paragraph: 'We are on the way to implement the idea of creating an ecosystem for entrepreneurs, in which they could perform all the activities related to starting and maintaining a business in one app. Our FIRMDER app will perform the registration and maintenance of companies according to your instructions. We inform you in a timely manner about all the events related to your company and the further necessary actions through push notifications and chat.',
			cards: [
				{
					info: 'Soon',
					heading: 'Bank',
					paragraph: `For the year 2024, we are developing our most ambitious and groundbreaking product - business banking. FIRMDER Bank, with its solid customer base, will be able to convert them into customers of banking services. Now it's a big secret, but one of the distinguishing features is analytical tips on your business transactions and card settlements, about what can be submitted as your business expenditures and this will help you save on taxes.
					We'll make your accountant happier.
					`,
				},
				{
					info: 'Soon',
					heading: 'Marketplace',
					paragraph: 'Early 2023, we will introduce a major update. This is a marketplace of ready-made companies. We let our customers to sell their companies previously opened with us, and interested parties to buy a ready-made business with verified information. You can set the price for the company yourself. Our ML based system will recommend an effective sale price and advise you on how to increase the price of your ready-made company. The sale of ready-made companies will allow you to close selling or purchasing transactions safely through the FIRMDER marketplace, and it will always be nice for you to understand that you will return all your money invested in registering a new company by selling it. Now we can say: pay now, and return your money later.',
				}
			],
		},
		section6: {
			heading: 'Join FIRMDER even as you read it!',
			paragraph: 'In the future we will be increasing the number of countries to register and expanding our service to USA, then UK, then Netherlands, then Hong Kong and further to the whole world with all mentioned features. In this way, one gets the opportunity to open businesses all over the world. That means our main target is to make the efforts of every entrepreneur around the world easier, that includes leadership, management and so on.',
		},
		footer: {
			tools: 'Tools',
			services: 'Services',
			investors: 'Investors',
			termsConditions: 'Terms & Conditions',
		},
		CTA: {
			heading: 'Hey there',
			message: 'Join our community and get early access to Shareup and learn about product updates',
			button: 'Sign up',
			status: 'Got it. 😎 Thanks!',
		},
		termsAndConditions,
	},
	de: {
		header: {
			signIn: 'EInloggen Mein',
			startBusiness: 'Bedingungen und Voraussetzungen',
		},
		hero: {
			heading: 'Nie war es einfacher, ein Unternehmen zu gründen',
			paragraph: `Der einfachste Weg für jeden, ein Unternehmen zu gründen und zu führen. 
			Gründen Sie Ihr Unternehmen, greifen Sie auf die Tools zu und verwalten Sie alles, was Ihr Unternehmen braucht - alles online und von überall.`,
			button: 'Ihr Unternehmen gründen',
		},
		section1: {
			heading: 'Starten Sie Ihr Unternehmen blitzschnell von überall auf der Welt aus',
			paragraph: 'Unser Unternehmen glaubt an die Idee, bei der ein Unternehmer alles schafft, was Menschen umgibt. Unabhängig davon, in welchem Raum Sie sich befinden, alles was Sie um sich herum sehen – wurde einmal bereits von einem Unternehmer erschaffen, eine Person, die einst zu handeln anfing und zu riskieren wagte, ohne sich vor möglichen Problemen oder Hindernissen zu fürchten.',
		},
		section2: {
			features: [
				{
					heading: 'Unternehmen mit zwei Klicks öffnen',
					paragraph: 'FIRMDER ist ein Service, der Unternehmern hilft, Unternehmen schnell und vor allem einfach zu registrieren.',
				},
				{
					heading: 'Alle notwendigen Dienstleistungen anschließen',
					paragraph: 'Wir bieten unseren Kunden die Dienstleistungen in steuerlicher Erfassung, Bürovermietung, Postweiterleitung, lokale Telefon- und Firmensekretariatsdienstleistungen, sowie die Entwicklung von Firmenwebsites und andere Begleitdienstleistungen, um unsere Kunden bei der Realisierung ihrer Geschäfte zu unterstützen.',
				},
				{
					heading: 'Alle Prozesse aus einem Panel kontrollieren',
					paragraph: '',
				}
			],
			paragraph: {
				primary: 'Leider bieten heutzutage die meisten Länder einen „manuellen“ Service, das heißt, alles muss separat gelöst werden. Das bedeutet z.B. zu Anwälten zu gehen, Fragen durch verschiedene öffentliche Einrichtungen zu behandeln, eine Menge Papiere auszufüllen und so weiter. Und das Ganze muss erledigt werden, einfach nur um ein eigenes Unternehmen zu gründen und zu verstehen, wie man das leitet.',

				secondary: 'Wir haben aber vor, den Prozess der Unternehmensgründung zu digitalisieren, d.h. vereinfachen.',
			},
		},
		section3: {
			heading: 'Tools für eine einfache Unternehmensführung',
			features: [
				{
					heading: 'Schnell',
					paragraph: 'Schneller Zugriff auf die Infrastruktur Ihres Unternehmens von überall auf der Welt aus. Unternehmensbuchhaltung, Briefverkehr, örtliche Telefonnummer und Firmensekretariatsdienstleistungen.',
				},
				{
					heading: 'Gewinnbringend',
					paragraph: 'Schneller Verkauf Ihres Unternehmens durch unsere Marketplace für Vorratsgesellschaften (in Kürze verfügbar).',
				},
				{
					heading: 'Immer daneben',
					paragraph: 'Unsere Live-Chat-Fachleute stehen Ihnen jederzeit zur Verfügung, um alle Ihre Fragen zu beantworten. Wenden Sie sich für steuerliche Unterstützung an Ihren eigenen Steuerberater. Oder an einen Manager, der Änderungen vornimmt oder Ihr Unternehmen leitet.',
				},
				{
					heading: 'Innovative',
					paragraph: 'FIRMDER Bank ist eine revolutionäre Lösung für die Eröffnung eines Firmenkontos im FIRMDER-Ökosystem. Unsere Technologien Machine Learning (ML) wählen aus, welche Transaktion wir zur Ausgabe empfehlen. Wir machen Ihren Buchhalter glücklicher.',
				}
			],
		},
		section4: {
			heading: 'FIRMDER Nebenleistungen',
			paragraph: 'Mit der FIRMDER-App und der Web-Version des Services können Sie zusätzliche Leistungen in Anspruch nehmen.',
			cards: [
				{
					heading: 'Virtual Office',
					paragraph: 'Scannen von Briefen, die an die Adresse Ihres Unternehmens gerichtet werden.',
				},
				{
					heading: 'Buchhaltung',
					paragraph: 'Bei der Lösung verschiedener Aufgaben nutzen wir unsere bereits fünfjährige Erfahrung im Bereich sowohl der Geschäftsleitung und Gründung von Unternehmen weltweit als auch im Bereich der Buchhaltung für zahlreiche Kunden. Folglich sind wir uns aller Probleme und Aspekte bewusst, auf die ein Unternehmer stoßen kann, und sind bereit, die mithilfe von unserem Service Firmder zu lösen. ',
				},
				{
					heading: 'Registrierung von Handelsmarke',
					paragraph: 'Ihr Unternehmen wird immer durch FIRMDER TM geschützt sein. Wir registrieren die Handelsmarke für Ihr Unternehmen in der ganzen EU.',
				}
			],
		},
		section5: {
			heading: 'Was planen wir sonst noch?',
			paragraph: 'Wir sind auf dem Weg, die Idee zu verwirklichen, ein Ökosystem für Unternehmer zu schaffen, in dem sie alle Schritte, die mit der Unternehmensgründung und -Service verbunden sind, in einer App erledigen können. Unsere FIRMDER App übernimmt die Registrierung und Pflege von Unternehmen nach Ihren Vorgaben. Wir informieren Sie zeitnah über alle Ereignisse, die Ihr Unternehmen betreffen, und über notwendige Maßnahmen, die durch Push-Benachrichtigungen und Chats unterstützt werden.',
			cards: [
				{
					info: 'Demnächst',
					heading: 'Bank',
					paragraph: `Bis 2024 entwickeln wir unser ehrgeizigstes und revolutionäres Produkt - das Business Banking. Die FIRMDER-Bank, die über einen aufgebauten großen Kundenpool verfügt, wird in der Lage sein, sie in Bankkunden zu konvertieren. Es ist noch ein großes Geheimnis, aber eines der besonderen Merkmale werden analytische Hinweise auf Ihre Geschäftstransaktionen und Kartenabrechnungen darüber sein, welche Transaktionen als Unternehmensausgaben steuerlich abgesetzt werden können, und das wird Ihnen helfen, Steuern zu sparen.
					Wir machen Ihren Buchhalter glücklicher.
					`,
				},
				{
					info: 'Demnächst',
					heading: 'Marketplace',
					paragraph: 'Anfang 2023 werden wir ein größeres Upgrade einführen. Dies ist ein Marketplace von fertigen Unternehmen. Wir ermöglichen es unseren Kunden, ihre bereits eröffneten Unternehmen an uns zu verkaufen, und Interessenten, ein Großhandelsunternehmen mit der verifizierten Information zu kaufen. Sie können den Preis des Unternehmens selbst festlegen. Unser System, das sich auf ML Technologien stützt, gibt eine Empfehlung für einen vorteilhaften Verkaufspreis ab und berät Sie, wie Sie den Preis für Ihr fertiges Unternehmen anheben können. Der Verkauf von Vorratsgesellschaften ermöglicht es Ihnen, sicher über den FIRMDER Marketplace zu verkaufen oder zu kaufen und immer zu wissen, dass Sie Ihre gesamte Investition in die Registrierung eines neuen Unternehmens durch dessen Verkauf zurückzuerstatten. Dann können wir sagen: Zahlen Sie jetzt und erhalten Sie Ihr Geld später zurück.',
				}
			],
		},
		section6: {
			heading: 'Schon jetzt FIRMDER beitreten!',
			paragraph: `In Zukunft werden wir die Anzahl der zu registrierenden Länder erhöhen und unseren Service mit allen erwähnten Funktionen auf die USA, dann auf Großbritannien, dann auf die Niederlande, dann auf Hongkong und weiter auf die ganze Welt ausweiten. 
			Auf diese Weise bekommt man die Möglichkeit, Unternehmen auf der ganzen Welt zu eröffnen. Das heißt, unser Hauptziel ist es, die Arbeit von jedem Unternehmer weltweit einfacher zu gestatten, das umfasst die Führung, das Management und so weiter.
			`,
		},
		footer: {
			tools: 'Tools',
			services: 'Dienstleistungen',
			investors: 'Investoren',
			termsConditions: 'Bedingungen und Voraussetzungen',
		},
		CTA: {
			heading: 'Sie da',
			message: 'Treten Sie unserer Community bei und erhalten Sie frühen Zugriff auf Shareup und erfahren Sie mehr über Produktaktualisierungen',
			button: 'Anmelden',
			status: 'Habe es. 😎 Danke!',
		},
		termsAndConditions,
	},
};