import { useEffect, useRef, useState } from 'react';
import './InfoCard.scss';
import { motion } from 'framer-motion';

interface IProps {
  heading: string;
  text?: string;
  image?: string;
  width?: string;
  animation?: boolean;
  state?: boolean;
  phonePic?: string;
}
const InfoCard: React.FC<IProps> = ({
  heading,
  text,
  image,
  width,
  animation,
  state,
  phonePic,
}) => {
  const imageBgRef = useRef<HTMLImageElement>(null);
  const [imageBgWidth, setImageBgWidth] = useState<number>();

  //sets heights
  useEffect(() => {
    const handleScroll = () => {
      const imageBgWithCurrent = imageBgRef.current?.clientWidth;
      setImageBgWidth(imageBgWithCurrent);
      //console.log(idx == 0 ? scrollPos : '');
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('risize', handleScroll);
    };
  }, []);

  return (
    <div
      className='infoCard'
      style={{
        width: width ? width : 'auto',
        maxWidth: width ? width : 'none',
        opacity: state !== undefined ? (state ? '1' : '0') : '1',
      }}
    >
      {animation ? (
        <motion.div
          animate={state ? 'open' : 'closed'}
          variants={{
            open: {
              opacity: 1,
              scale: [0.6, 1],
            },
            closed: {
              opacity: 0,
              scale: [1, 0.6],
            },
          }}
          transition={{ duration: 0.4 }}
        >
          <div className='infoCard__head'>
            <h3 className='infoCard__heading'>{heading}</h3>
            {text && <div className='infoCard__text'>{text}</div>}
          </div>
        </motion.div>
      ) : (
        <div className='infoCard__head'>
          <h3 className='infoCard__heading'>{heading}</h3>
          {text && <div className='infoCard__text'>{text}</div>}
        </div>
      )}
      {animation
        ? image &&
          phonePic && (
            <div
              className='section3__feature-image-container'
              style={{ width: imageBgWidth, minWidth: '10rem' }}
            >
              <img
                ref={imageBgRef}
                src={phonePic}
                alt=''
                className='section3__feature-image-bg'
                //ref={imageBgRef}
                style={{
                  opacity: state ? '1' : '0',
                }}
              />
              <motion.div
                animate={state ? 'open' : 'closed'}
                variants={{
                  open: {
                    opacity: 1,
                  },
                  closed: {
                    opacity: 0,
                  },
                }}
                className='section3__feature-image'
              >
                {' '}
                <img src={image} alt='Image' />
              </motion.div>
            </div>
          )
        : image && (
            <div className='infoCard__image-container'>
              <img src={image} alt='Image' className='infoCard__image' />
            </div>
          )}
    </div>
  );
};

export default InfoCard;
