const database = ({ ...props }) => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect width="40" height="40" rx="12" fill="#FEDC7A" />
			<ellipse
				cx="20"
				cy="15"
				rx="7"
				ry="3"
				stroke="#2E3A59"
				strokeWidth="2"
			/>
			<path
				d="M13 21C13 21 13 23.3431 13 25C13 26.6569 16.134 28 20 28C23.866 28 27 26.6569 27 25C27 24.173 27 21 27 21"
				stroke="#2E3A59"
				strokeWidth="2"
				strokeLinecap="square"
			/>
			<path
				d="M13 15C13 15 13 18.3431 13 20C13 21.6569 16.134 23 20 23C23.866 23 27 21.6569 27 20C27 19.173 27 15 27 15"
				stroke="#2E3A59"
				strokeWidth="2"
			/>
		</svg>
	);
};

export default database;
