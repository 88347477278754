import { useEffect, useRef, useState } from 'react';
import { useLanguage } from 'hooks';
import { handleSignupVisibility } from 'utilities';
import { CloseRound, Spinner, Stars } from 'assets';
import { storeEmail, wait } from 'helpers';
import './Signup.scss';

const Signup = () => {
	const signupCloseRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [inputValue, setInputValue] = useState('');
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [isSent, setIsSent] = useState(false);
	const { CTA } = useLanguage();

	useEffect(() => {
		const closeTarget = signupCloseRef.current;
		closeTarget?.addEventListener('click', handleSignupVisibility);
		return () => {
			closeTarget?.removeEventListener('click', handleSignupVisibility);
		};
	});

	function closePopup() {
		signupCloseRef.current?.click();
		setIsSent(false);
	}

	async function sendData() {
		const result = await storeEmail(inputValue);
		if (!result) {
			closePopup();
			setFormSubmitted(false);
			return;
		}
		setInputValue('');
		setIsSent(true);
		setFormSubmitted(false);
		wait(3000, closePopup);
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!inputValue) return;
		setFormSubmitted(true);
		return sendData();
	};

	const updateInputValue = (event: React.ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		setInputValue(target.value);
	};

	const messageClass = `signup__form-message ${isSent ? 'message-show' : ''}`;

	return (
		<div className="signup">
			<div className="signup__form">
				<div className="signup__form--background">
					<div className="signup__form--container">
						<Stars />
						<div className="signup__form--wrapper">
							<h2 className="signup__heading">{CTA.heading}</h2>
							<p className="signup__info">{CTA.message}</p>
							<form className="signup__fields" onSubmit={handleSubmit}>
								<input
									value={inputValue}
									onChange={updateInputValue}
									type="email"
									className="input-reset signup__email-field"
									placeholder="Email"
									ref={inputRef}
								/>
								<div className="signup__message-container">
									<span className={messageClass}>
										<p>
											<strong>{CTA.status}</strong>
										</p>
									</span>
								</div>
								<button type="submit" className="signup__button">
									{formSubmitted && !isSent && (
										<Spinner
											primaryColor="#ebc971"
											secondaryColor="#BE9EFF"
											className="spin"
											width={32}
											height={32}
										/>
									)}
									{!formSubmitted && CTA.button}
								</button>
							</form>
						</div>
						<div className="signup__form--close" ref={signupCloseRef}>
							<CloseRound className="close-svg" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Signup;
