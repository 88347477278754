import { useEffect } from 'react';
import { handleSignupVisibility } from 'utilities';

interface Props {
	listenOn: React.RefObject<HTMLElement>[];
}

const useSignup = (listenOn?: Props['listenOn']) => {

	function addListeners() {
		listenOn?.forEach(({ current }) => {
			current?.addEventListener('click', handleSignupVisibility);
		});
	}

	function removeListeners() {
		listenOn?.forEach(({ current }) => {
			current?.removeEventListener('click', handleSignupVisibility);
		});
	}

	useEffect(() => {
		addListeners();
		return () => removeListeners();
	});

	return [];
};

export default useSignup;
