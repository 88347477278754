/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { to2Digits, isEven, screenWidthGreaterThan } from 'helpers';
import { HeadingParagraph } from 'Types/ILanguage';
import { InfoCard } from 'components';
import iPhoneFast from 'assets/iPhone_fast.png';
import iPhoneAdvantageous from 'assets/iPhone_advantageous.png';
import iPhoneNearby from 'assets/iPhone_nearby.png';
import iPhoneGroundBreaking from 'assets/iPhone_groundbreaking.png';
import Typed from 'react-typed';
import { useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import phoneBg from 'assets/phone-main.png';

// import { useRef, useEffect } from 'react';
interface IProps {
  features: HeadingParagraph[];
  setFeaturesHeight: React.Dispatch<React.SetStateAction<number>>;
  sectionHeight: number;
  sectionTop: number;
  scrollPosition: number;
  featIndex: number;
  changeHeight: number;
}

const Feature: React.FC<IProps> = ({
  features,
  setFeaturesHeight,
  sectionHeight,
  sectionTop,
  scrollPosition,
  featIndex,
  changeHeight,
}) => {
  const [featureHeight, setFeatureHeight] = useState<number>(0);
  const feature = features.map((el, idx) => {
    let image: string;
    switch (idx) {
      case 0:
        image = iPhoneFast;
        break;
      case 1:
        image = iPhoneAdvantageous;

        break;
      case 2:
        image = iPhoneNearby;

        break;
      case 3:
        image = iPhoneGroundBreaking;

        break;
      default:
        image = iPhoneFast;
    }

    // UNO)
    const reverse = isEven(idx) ? 'abs--l0' : 'abs--r0';

    const featureInfoClass = `section3__feature--info ${reverse}`;
    const FeatureElement = () => {
      const [elemPosition, setElemPosition] = useState<DOMRect>();
      const [imageBgWidth, setImageBgWidth] = useState<number>();
      const featureRef = useRef<HTMLDivElement>(null);
      const imageBgRef = useRef<HTMLImageElement>(null);
      const [stopped, setStopped] = useState(false);
      let num = 9;

      //sets heights
      useEffect(() => {
        const handleScroll = () => {
          const elemPos = featureRef.current!.getBoundingClientRect();
          const elemHeight = featureRef.current!.clientHeight;
          const imageBgWithCurrent = imageBgRef.current?.clientWidth;
          const scrollPos = window.scrollY;
          setElemPosition(elemPos);
          setFeatureHeight(elemHeight);
          setImageBgWidth(imageBgWithCurrent);
          //console.log(idx == 0 ? scrollPos : '');
        };
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('resize', handleScroll, { passive: true });

        return () => {
          window.removeEventListener('scroll', handleScroll);
          window.removeEventListener('risize', handleScroll);
        };
      }, [stopped, num]);

      //set height of features big wrapper
      useEffect(() => {
        setFeaturesHeight(200 * features.length);
      }, []);
      // transform: `rotateX(${
      //   elemPosition.top ? elemPosition.top / num : 0
      // }deg)`,
      return (
        <div
          ref={featureRef}
          key={idx}
          className='section3-feature-wrapper'
          // style={{
          //   opacity: getOpacity() ? '1' : '0',
          // }}
        >
          <div className='section3-responsive-feature'>
            {screenWidthGreaterThan(1000) ? (
              <div key={idx} className='section3__feature'>
                <motion.div
                  animate={
                    idx == featIndex ||
                    (featIndex > features.length - 1 &&
                      idx == features.length - 1)
                      ? 'open'
                      : 'closed'
                  }
                  variants={{
                    open: {
                      opacity: 1,
                      y: '0',
                      scale: [0.6, 1],
                    },
                    closed: {
                      opacity: 0,
                      y: '300px',
                      scale: [1, 0.6],
                    },
                  }}
                  transition={{ duration: 0.4 }}
                  className={featureInfoClass}
                  data-value={to2Digits(idx + 1)}
                >
                  <div
                    className={'section3__feature-heading'}
                    style={{ display: stopped ? 'none' : 'block' }}
                  >
                    <Typed
                      strings={[el.heading]}
                      typeSpeed={80}
                      onComplete={() => {
                        setTimeout(() => setStopped(true), 200);
                      }}
                    />
                  </div>
                  <h3 style={{ display: stopped ? 'block' : 'none' }}>
                    {el.heading}
                  </h3>
                  <p>{el.paragraph}</p>
                </motion.div>
                <div
                  className='section3__feature-image-container'
                  style={{ width: imageBgWidth, minWidth: '10rem' }}
                >
                  <img
                    src={phoneBg}
                    alt=''
                    className='section3__feature-image-bg'
                    ref={imageBgRef}
                    style={{
                      opacity:
                        idx == featIndex ||
                        (featIndex > features.length - 1 &&
                          idx == features.length - 1)
                          ? '1'
                          : '0',
                    }}
                  />
                  <motion.div
                    animate={
                      idx == featIndex ||
                      (featIndex > features.length - 1 &&
                        idx == features.length - 1)
                        ? 'open'
                        : 'closed'
                    }
                    variants={{
                      open: {
                        opacity: 1,
                      },
                      closed: {
                        opacity: 0,
                      },
                    }}
                    className='section3__feature-image'
                  >
                    <img src={image} alt='iPhone' />
                  </motion.div>
                </div>
              </div>
            ) : (
              <InfoCard
                width='100%'
                key={idx}
                heading={el.heading}
                text={el.paragraph}
                image={image}
                animation={true}
                state={
                  idx == featIndex ||
                  (featIndex > features.length - 1 &&
                    idx == features.length - 1)
                }
                phonePic={phoneBg}
              />
            )}
          </div>
        </div>
      );
    };

    return FeatureElement;
  });

  return (
    <>
      <div
        className={`section3-bar ${
          screenWidthGreaterThan(1000) &&
          window.innerWidth - window.innerHeight > 300
            ? ''
            : 'section-bar-sm'
        }`}
        style={{ height: `${featureHeight - 50}px` }}
      >
        <div className='section3-bar__content'>
          <div
            className='section3-bar__content__active'
            style={{
              height: `${
                ((scrollPosition - sectionTop + 1000) * 100) / sectionHeight
              }%`,
            }}
          ></div>
        </div>
      </div>
      {feature.map(component => component())}
    </>
  );
};

export default Feature;
