import { useRef } from 'react';
import { BackgroundVideos } from 'utilities';
import { useLanguage, useSignup, useWindowDimensions } from 'hooks';
import './Hero.scss';
import '../../config/i18n/i18n';

//import Helmet from 'react-helmet';

const Hero = () => {
  const { hero } = useLanguage();
  const startBusinessRef = useRef(null);
  const { width } = useWindowDimensions();
  useSignup([startBusinessRef]);

  return (
    <div className='hero'>
      <div className='hero__bg-content'>
        <video
          className='hero__bg-video--content'
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            src={
              width > 1080 ? BackgroundVideos.desktop : BackgroundVideos.mobile
            }
          />
          {/* Your browser isn't supported */}
        </video>
      </div>
      <div className='hero__info'>
        <h1 className='hero__heading'>{hero.heading}</h1>
        <p className='hero__text'>{hero.paragraph}</p>
        <button className='button__primary' ref={startBusinessRef}>
          {hero.button}
        </button>
      </div>
    </div>
  );
};

export default Hero;
