import { InfoCard } from 'components';
import { HeadingParagraph } from 'Types';

interface IProps {
	features: Array<HeadingParagraph>;
}

const ListInfoCards: React.FC<IProps> = ({ features }) => {
	const Cards = () => (
		<>
			{features.map(({ heading, paragraph }, idx) => (
				<InfoCard key={idx} heading={heading} text={paragraph} />
			))}
		</>
	);

	return <Cards />;
};

export default ListInfoCards;
