const SignIn = ({ ...props }) => {
	return (
		<svg
			width="24"
			height="42"
			viewBox="0 0 24 42"
			fill="#464A54"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.831132 1.68806C-0.228863 2.74806 -0.228863 4.46665 0.831132 5.52664L16.5547 21.2502L0.831129 36.9738C-0.228866 38.0338 -0.228866 39.7524 0.831129 40.8124C1.89112 41.8724 3.60971 41.8724 4.66971 40.8124L22.3126 23.1695C23.3726 22.1095 23.3726 20.3909 22.3126 19.3309L4.66971 1.68806C3.60972 0.628068 1.89113 0.628068 0.831132 1.68806Z"
			/>
		</svg>
	);
};

export default SignIn;
