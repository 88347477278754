import { useRef } from 'react';
import { useLanguage, useSignup } from 'hooks';
import { AppleMobile, PlaystoreMobile } from 'assets';
import './Section6.scss';

const Section6 = () => {
	const { section6 } = useLanguage();
	const appleRef = useRef(null);
	const googleRef = useRef(null);
	useSignup([appleRef, googleRef]);

	return (
		<section className="section6">
			<div className="section6__info">
				<h3 className="gradient--text">{section6.heading}</h3>
				<p>{section6.paragraph}</p>
			</div>
			<div className="section6__download">
				<PlaystoreMobile ref={googleRef} className='svg-fade' />
				<AppleMobile ref={appleRef} />
			</div>
		</section>
	);
};

export default Section6;
