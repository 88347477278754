import { useLanguage } from 'hooks';
import Feature from './Feature';
import './Section3.scss';
import { useEffect, useRef, useState } from 'react';

const Section3 = () => {
  const { section3 } = useLanguage();
  const [featuresHeight, setFeaturesHeight] = useState<number>(0);
  const [sectionHeight, setSectionHeight] = useState<number>(0);
  const [sectionTop, setSectionTop] = useState<number>(0);
  const [featIndex, setFeatIndex] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const changeHeight = sectionHeight / section3.features.length;

  const section3Ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      const section3height = section3Ref.current!.offsetHeight;
      const section3top = section3Ref.current!.offsetTop;
      setSectionHeight(section3height);
      setSectionTop(section3top);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [featuresHeight]);

  //sets scroll position
  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY;
      setScrollPosition(scrollPos);
      //console.log(idx == 0 ? scrollPos : '');
    };
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const diff = scrollPosition - sectionTop;
    const slideIndex = Math.round(diff / changeHeight);
    setFeatIndex(slideIndex);
  }, [changeHeight, scrollPosition, sectionTop, featIndex]);

  return (
    <section
      className='section3'
      style={{ height: `${featuresHeight}vh` }}
      ref={section3Ref}
    >
      <div
        className='section3__heading'
        style={{
          position: 'static',
        }}
      >
        <h2>{section3.heading}</h2>
      </div>
      <div className='section3__features' style={{ height: '100vh' }}>
        <Feature
          features={section3.features}
          setFeaturesHeight={setFeaturesHeight}
          sectionHeight={sectionHeight}
          sectionTop={sectionTop}
          scrollPosition={scrollPosition}
          featIndex={featIndex}
          changeHeight={changeHeight}
        />
      </div>
    </section>
  );
};

export default Section3;
