import { useKineograpgh } from 'hooks';
import './Cube.scss';

interface IProps {
  leftSide: JSX.Element;
}

const Cube: React.FC<IProps> = ({ leftSide }) => {
  const kineographOptions = {
    scrollHeight: window.innerWidth >= 1100 ? 1706 : 999,
    width: 1190,
    height: 964,
    numFrames: 130,
    framesURL: '/assets/framing',
    startOn: 1800,
    addExtraHeight: true,
  };
  const [Canvas] = useKineograpgh(kineographOptions);
  return (
    <main className='mainc'>
      {Canvas}
      {leftSide}
    </main>
  );
};

export default Cube;

// @media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }

// @media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }

// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
