import { useLayoutEffect, useRef } from 'react';
import { Language, SignIn, StartBusiness } from 'assets';
import { useSignup } from 'hooks';

interface IProps {
	isOpened: boolean;
	actions: {
		languageHandler: (ev: React.MouseEvent<HTMLDivElement>) => void;
		currentLanguage: string;
		signIn: string;
		startBusiness: string;
		toggleLanguage: string;
	};
}

const NavigationMenu: React.FC<IProps> = ({ isOpened, actions }) => {
	const menuStyle = `header__navigation ${
		isOpened ? 'header__navigation--in' : ''
		}`;
	
	const signinRef = useRef(null); 
	const startBusinessRef = useRef(null); 
	useSignup([signinRef, startBusinessRef]);

	useLayoutEffect(() => {
		document.body.style.overflow = isOpened ? 'hidden' : 'auto';
	}, [isOpened]);



	return (
		<nav className={menuStyle}>
			{/* <div className="header__navigation--logo">
				<IconFirmder width={102} height={25} />
			</div> */}
			{/* FC9790 */}
			<div className="header__navigation-items">
				<ul>
					<div onClick={actions.languageHandler}>
						<li>
							<span>
								<Language />
							</span>
							<span data-language={actions.currentLanguage}>{actions.toggleLanguage}</span>
						</li>
					</div>
					<li ref={signinRef}>
						<span>
							<SignIn />
						</span>
						<span>{actions.signIn}</span>
					</li>
					<li ref={startBusinessRef}>
						<span>
							<StartBusiness />
						</span>
						<span>{actions.startBusiness}</span>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default NavigationMenu;
