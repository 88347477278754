import { useRef, useState } from 'react';
import { useGlobalContext } from 'utilities';
import { useSignup } from 'hooks';
import { Logo } from 'assets';
import NavigationMenu from './NavigationMenu';
import './Header.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  currentState: number;
}

const Header: React.FC<IProps> = ({ currentState }) => {
  const [hamburgerClicked, setHamburgerClicked] = useState(false);

  const signupRef = useRef(null);
  const startBusinessRef = useRef(null);
  const btnStartRef = useRef(null);
  useSignup([signupRef, startBusinessRef, btnStartRef]);

  const data = useGlobalContext();
  const { header } = data[data.language];

  const { i18n } = useTranslation();

  const handleChange = (ev: React.MouseEvent<HTMLDivElement>) => {
    const target = ev.target as HTMLElement;
    if (target.dataset.language?.toLowerCase() === 'en') {
      data.setLanguage('de');
      i18n.changeLanguage('de');
    } else {
      data.setLanguage('en');
      i18n.changeLanguage('en');
    }
  };

  const btnStartText =
    data.language === 'en' ? 'Start my business' : 'EInloggen Mein';

  const navigationMenuState = currentState ? 1 : hamburgerClicked ? 1 : 0; // fixed bug in android
  const headerCases = ['header__hide', 'header__show'];
  const headerStyle = `header ${headerCases[navigationMenuState]} header__transition`;

  const navigationActions = {
    languageHandler: handleChange,
    currentLanguage: data.language.toUpperCase(),
    signIn: header.signIn,
    startBusiness: header.startBusiness,
    toggleLanguage: data.language.toLowerCase() === 'en' ? 'EN' : 'DE',
  };

  type NavigationActions = Pick<
    typeof navigationActions,
    | 'languageHandler'
    | 'currentLanguage'
    | 'signIn'
    | 'startBusiness'
    | 'toggleLanguage'
  >;

  const HeaderActions: React.FC<NavigationActions> = ({
    languageHandler,
    currentLanguage,
    signIn,
    startBusiness,
    toggleLanguage,
  }) => {
    return (
      <>
        <div
          onClick={languageHandler}
          data-language={currentLanguage}
          className='header__right--lang'
        >
          {toggleLanguage}
        </div>
        <div className='header__right--signin' ref={signupRef}>
          {signIn}
        </div>
        <div className='button__secondary' ref={startBusinessRef}>
          {startBusiness}
        </div>
      </>
    );
  };

  const hamburgerStyle = `header__hamburger ${
    hamburgerClicked ? 'header__hamburger--active' : ''
  }`;

  const toggleHamburger = () => setHamburgerClicked(!hamburgerClicked);

  return (
    <>
      <header className={headerStyle}>
        <div className='header__left-side'>
          <div className='header__logo'>
            <Logo className='header__logo--img' />
          </div>
          <div className='header__right'>
            <div onClick={toggleHamburger} className={hamburgerStyle}>
              <div className='header__hamburger--1' />
              {/* <div className="header__hamburger--2" /> */}
              <div className='header__hamburger--3' />
            </div>
            <div className='header__right--container'>
              <HeaderActions {...navigationActions} />
            </div>
          </div>
        </div>

        <div className='btn-start'>
          <button ref={btnStartRef} className='button__secondary'>
            {btnStartText}
          </button>
        </div>
      </header>
      <NavigationMenu actions={navigationActions} isOpened={hamburgerClicked} />
    </>
  );
};

export default Header;
