import { useMemo, useState } from 'react';
import {
  Footer,
  Header,
  Hero,
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Signup,
} from 'components';
import { GlobalContext, text } from './utilities';
import { useNavigationEffect } from 'hooks';
import './App.scss';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

/* Move project to next fotr good SEO */
function App() {
  const { i18n, t } = useTranslation();

  const [language, setLanguage] = useState<'en' | 'de'>(
    i18n.language as 'en' | 'de',
  );
  const languageMemo = useMemo(
    () => ({ language, setLanguage }),
    [language, setLanguage],
  );
  const [scrollState] = useNavigationEffect();
  return (
    <div className='App'>
      <Helmet>
        <title>{t('title')}</title>
        <meta name='description' content={t('description')} />
      </Helmet>
      <GlobalContext.Provider value={{ ...text, ...languageMemo }}>
        <Header currentState={scrollState} />
        <Hero />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Footer />
        <Signup />
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
