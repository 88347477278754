import { createContext, useContext } from 'react';
import { IText } from 'Types';
import { text } from 'utilities';

interface ILanguage extends IText {
  language: 'en' | 'de';
  setLanguage: React.Dispatch<React.SetStateAction<'en' | 'de'>>;
}

export const GlobalContext = createContext<ILanguage>({
  ...text,
  language: 'en',
  setLanguage: () => null,
});

export const useGlobalContext = () => useContext(GlobalContext);
