import { API_KEY, endpoints } from 'API';
import { lazy } from 'react';

export const to2Digits = (value: number) => {
  return value.toString().padStart(2, '0');
};

export function isOdd(n: number) {
  return Math.abs(n % 2) == 1;
}

export function isEven(n: number) {
  return n % 2 == 0;
}

function validateEmail(email: string) {
  var re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return re.test(email);
}

type FetchType = 'GET' | 'POST' | 'DELETE';
type DataType = { [key: string]: string };
interface IRequestType {
  method: FetchType;
  headers: { [key: string]: string };
  body?: string;
}
async function fetchRequest<T extends FetchType, D extends DataType>(
  type: T,
  data: D,
) {
  const requestOptions: IRequestType = {
    method: type,
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
  };

  if (!(type === 'GET')) {
    requestOptions.body = JSON.stringify({ ...data });
  }

  const result = await fetch(endpoints.email, requestOptions);
  return result.json();
}

export async function storeEmail(email: string) {
  if (!validateEmail(email)) return false;
  return fetchRequest('POST', { email });
}

export function screenWidthGreaterThan(width: number) {
  return window.innerWidth > width;
}
export function screenHeightGreaterThan(height: number) {
  return window.innerHeight > height;
}

export function wait(duration: number, fnc: Function) {
  setTimeout(() => {
    fnc();
  }, duration);
}

export function getCurrentFrame(index: number, framesURL: string) {
  return `${framesURL}/${index.toString().padStart(4, '0')}.png`;
}
export function getCurrentFrame2(index: number, framesURL: string) {
  return `${framesURL}/${index.toString().padStart(8, '01_00000')}.png`;
}

export function lazyLoad(path: string, namedExport?: string) {
  return lazy(() => {
    const promise = import(`../components/${path}`);
    if (!namedExport) return promise;

    return promise.then(module => ({ default: module[namedExport] }));
  });
}

export { default as BulletMap } from './BulletMap';
