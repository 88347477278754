import iPhone from 'assets/iPhone.png';
import macbook from 'assets/macbook.png';
// import macbookMobile from 'assets/macbook-mobile.png';
import { useLanguage, useSignup } from 'hooks';
import { useRef } from 'react';
import './Section1.scss';

const Section1 = () => {
	const {
		section1,
		hero: { button: buttonText },
	} = useLanguage();
	const startBusinessRef = useRef(null);
	useSignup([startBusinessRef]);
	// const { width } = useWindowDimensions();
	// const macbookImg = width > 1100 ? macbook : macbookMobile;
	return (
		<section className="section1">
			<div className="section1--items">
				<div className="section1__images">
					<div className="image-box">
						<img src={iPhone} alt="iPhone" />
					</div>

					<div className="image-box">
						<img src={macbook} alt="macbook" />
					</div>
				</div>
				<div className="section1__info">
					<h1 className="section1__heading">{section1.heading}</h1>
					<p className="section1__text">{section1.paragraph}</p>
					<button className="button__primary" ref={startBusinessRef}>
						{buttonText}
					</button>
				</div>
			</div>
		</section>
	);
};

export default Section1;
