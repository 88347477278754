import { useEffect, useRef } from 'react';
import { useInView } from 'hooks';

interface ITriggers {
	up: () => void;
	down: () => void;
}

const useTriggerOnScroll = (triggers: ITriggers) => {
	const lastScrollTopRef = useRef(0);
	const [ref, , inView] = useInView();

	const scrollHandler = () => {
		if (!inView) return;
		var st = window.pageYOffset || document.documentElement.scrollTop;
		if (st > lastScrollTopRef.current && st >= 39) {
			// ('down');
			triggers.down();
			// increment();
		} else {
			// ('up');
			triggers.up();
			// decrement();
		}
		lastScrollTopRef.current = st <= 0 ? 0 : st; // For Mobile or negative scrolling

	};

	useEffect(() => {
		window.addEventListener('scroll', scrollHandler);

		return (() => {
			window.removeEventListener('scroll', scrollHandler);
		});
	});

	return [ref];
};

export default useTriggerOnScroll;