import { Card } from 'components';
import { useCardAnimation, useLanguage } from 'hooks';
// import { screenWidthGreaterThan } from 'helpers';
// import padlock from 'assets/padlock.png';
import './Section4.scss';
import { lazyLoad } from 'helpers';
const Lock2 = lazyLoad('lock/Lock2');

const Section4 = () => {
  const { section4 } = useLanguage();
  const [triggerOnRef, cardARef, cardBRef] = useCardAnimation();

  return (
    <section className='section4' id='services'>
      <div className='section4__image-box'>
        <Lock2 />
        {/*<img src={padlock} alt='padlock' className='section4__lock' />*/}
      </div>

      <div className='section4__header' id='section4__header'>
        <h3>{section4.heading}</h3>
        <p>{section4.paragraph}</p>
      </div>

      <div className='section4__features' ref={triggerOnRef}>
        <Card allRef={[cardARef, cardBRef]} features={section4.cards} />
      </div>
    </section>
  );
};

export default Section4;
