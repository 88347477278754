import { useState, useLayoutEffect } from 'react';
import './Modal.scss';
interface IProps {
	listenOn: React.RefObject<HTMLElement>;
	Body: React.ReactNode
}

const Modal: React.FC<IProps> = ({ listenOn, Body }) => {
	const [toggleState, setToggleState] = useState<null | string>(null);
	
	const handleClose = () => setToggleState('hide');

	function displayModal() {
		setToggleState('show');
	}


	useLayoutEffect(() => {
		if (toggleState === 'show') {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}
		
		const element = listenOn.current;
		element!.addEventListener('click', displayModal);

		return () => {
			element!.removeEventListener('click', displayModal);
		};
	}, [listenOn, toggleState]);


	const modalClass = toggleState ?? '';


	return (
		<div className={`modal ${modalClass}`}>
			<div className="modal--close" onClick={handleClose} />

			<div className="modal__body">
				{Body}
			</div>
		</div>
	);
};

export default Modal;
