import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import './Carousel.scss';

interface IProps {
	children: React.ReactChild | React.ReactNode;
	width?: number;
}
export const CarouselItem: React.FC<IProps> = ({ children, width }) => {
	return (
		<div className="carousel-item" style={{ width: width }}>
			{children}
		</div>
	);
};

const Carousel: React.FC<Omit<IProps, 'width'>> = ({ children }) => {
	const [activeIndex, setActiveIndex] = useState(0);
	// const [paused, setPaused] = useState(false);

	const updateIndex = (newIndex: number) => {
		if (newIndex < 0) {
			newIndex = React.Children.count(children) - 1;
		} else if (newIndex >= React.Children.count(children)) {
			newIndex = 0;
		}

		setActiveIndex(newIndex);
	};

	// Add interval switch
	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		if (!paused) {
	// 			updateIndex(activeIndex + 1);
	// 		}
	// 	}, 3000);

	// 	return () => {
	// 		if (interval) {
	// 			clearInterval(interval);
	// 		}
	// 	};
	// });

	const handlers = useSwipeable({
		onSwipedLeft: () => updateIndex(activeIndex + 1),
		onSwipedRight: () => updateIndex(activeIndex - 1),
	});

	return (
		<div
			{...handlers}
			className="carousel"
			// Mouse controller
			// onMouseEnter={() => setPaused(true)}
			// onMouseLeave={() => setPaused(false)}
		>
			<div
				className="inner"
				style={{
					transform: `translateX(${
						activeIndex === 0 ? 21 : (activeIndex * -30)
					}%)`,
				}}
			>
				{React.Children.map(children, child => {
					return React.cloneElement(child as React.ReactElement, { width: '' });
				})}
			</div>
			<div className="indicators">
				{/* <button
					onClick={() => {
						updateIndex(activeIndex - 1);
					}}
				>
					Prev
				</button> */}
				{React.Children.map(children, (_, index) => {
					return (
						<button
							className={`${index === activeIndex ? 'active' : 'inactive'}`}
							onClick={() => {
								updateIndex(index);
							}}
						>
							{''}
						</button>
					);
				})}
				{/* <button
					onClick={() => {
						updateIndex(activeIndex + 1);
					}}
				>
					Next
				</button> */}
			</div>
		</div>
	);
};

export default Carousel;
