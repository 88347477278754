const StartBusiness = ({ ...props }) => {
	return (
		<svg
			width="43"
			height="55"
			viewBox="0 0 43 55"
			fill="#464A54"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.6296 2.51778C18.635 -1.20645 24.2858 0.218262 24.2858 4.44805V18.0652H38.2994C41.3801 18.0652 43.3447 21.3544 41.8841 24.0669L26.5134 52.6125C24.5081 56.3367 18.8572 54.912 18.8572 50.6822V37.0651H4.84371C1.76296 37.0651 -0.201649 33.7759 1.25893 31.0634L16.6296 2.51778ZM18.8573 9.83102L7.11586 31.6365H20.2144C22.463 31.6365 24.2858 33.4593 24.2858 35.7079V45.2992L36.0272 23.4938H22.9287C20.6801 23.4938 18.8573 21.6709 18.8573 19.4223V9.83102Z"
			/>
		</svg>
	);
};

export default StartBusiness;
