import { Database, Mobile, Message } from 'assets';
import { HeadingParagraph } from 'Types/ILanguage';
import { useWindowDimensions } from 'hooks';
import './Card.scss';

interface IProps {
	features: HeadingParagraph[];
	hasUpdateMessage?: boolean;
	allRef?: Array<any>
}

// CSS in section4 is in charge
const Card: React.FC<IProps> = ({ features, hasUpdateMessage, allRef }) => {
	let Icon: typeof Database;
	const { width: screenWidth } = useWindowDimensions();

	const cards = features.map((feature, idx) => {
		const adjust = idx > 0 && !hasUpdateMessage ? 'feature--down' : '';
		const darkBackground =
			idx === 1 && !hasUpdateMessage ? 'feature--dark' : '';
		const animateEntry = idx === 0 ? 'box--a' : idx === features.length - 1 ? 'box--b' : '';

		let attachRef = idx === 0 ? allRef?.[idx] : idx === features.length - 1 ? allRef?.[allRef.length - 1] : null;

		if (screenWidth <= 1100) attachRef = null;
		
		switch (idx) {
			case 0:
				Icon = Database;
				break;
			case 1:
				Icon = Mobile;
				break;
			case 2:
				Icon = Message;
				break;
			default:
				Icon = Mobile;
		}

		const classes = `feature ${adjust} ${darkBackground} ${
			hasUpdateMessage ? 'feature--msg' : ''
		} ${hasUpdateMessage && idx === 0 ? 'feature--smile' : ''} ${animateEntry}`;

		return (
			<div key={idx + feature.heading} className={classes} data-value={feature.info ?? 'Soon'} ref={attachRef}>
				<div className="feature__info">
					<>
						<Icon />
						<h5>{feature.heading}</h5>
						<p>{feature.paragraph}</p>
					</>
				</div>
			</div>
		);
	});
	return <>{cards}</>;
};

export default Card;
