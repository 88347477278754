// function showHideClass(switchState: "show" | "hide") {
//   const element = document.querySelector(".signup");
//   if (!element) return;
//   if (switchState === "show") {
//     element.classList.add("signup--show");
//     element.classList.remove("signup--close");
//   } else {
//     element.classList.add("signup--close");
//     element.classList.remove("signup--show");
//   }
// }


export function handleSignupVisibility() {
  window.location.href = 'https://web.firmder.com';
  // const clickedTarget = ev.target as Element;
  // const buttonIsClicked = clickedTarget?.closest('svg')?.classList.contains('close-svg') || clickedTarget.classList.contains('signup__form--close');
  // if (!buttonIsClicked) showHideClass('show');
  // if (buttonIsClicked) showHideClass('hide');
}

export const BackgroundVideos = {
  desktop: '/assets/ext/video_desktop.mov',
  mobile: '/assets/ext/video_mobile.mp4'
};

export { text } from './languages';
export { GlobalContext, useGlobalContext } from './context';
