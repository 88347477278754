import { getCurrentFrame, getCurrentFrame2 } from 'helpers';
import { useState, useEffect, useRef, useCallback } from 'react';
import { IImgCanvas } from 'Types';

const useKineograph = ({
  scrollHeight,
  numFrames,
  width,
  height,
  framesURL,
  startOn,
  useHeightOnCanvas = true,
  addExtraHeight = false,
  type2 = false,
  viewportWidth = 0,
  viewportHeight = 0,
}: IImgCanvas) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [images, setImages] = useState<Array<HTMLImageElement>>([]);
  const [frameIndex, setFrameIndex] = useState(0);

  const preloadImages = useCallback(() => {
    for (let i = 0; i <= numFrames; i++) {
      const img = new Image();
      const imgSrc = type2
        ? getCurrentFrame2(i, framesURL)
        : getCurrentFrame(i, framesURL);
      img.src = imgSrc;
      setImages(prevImages => [...prevImages, img]);
    }
  }, [numFrames, framesURL, type2]);

  const getScrollFraction = useCallback(() => {
    const EXTRA_HEIGHT = 370;

    if (addExtraHeight) {
      return (window.scrollY - startOn) / (window.innerHeight + EXTRA_HEIGHT);
    }
    if (type2 || viewportWidth || viewportHeight) {
      //let minus = 1550;
      let multiplier = innerWidth / innerHeight / 1.2;
      console.log(
        type2
          ? `${
              (window.scrollY + 300 / multiplier - startOn) / (300 / multiplier)
            }`
          : '',
      );
      return (
        (window.scrollY + 300 / multiplier - startOn) / (300 / multiplier)

        //(window.scrollY - startOn) / (window.innerHeight - scrollHeight + 450)
      );
    }
    return (window.scrollY - startOn) / (scrollHeight - window.innerHeight);
  }, [
    startOn,
    addExtraHeight,
    scrollHeight,
    type2,
    viewportWidth,
    viewportHeight,
  ]);

  const handleScroll = useCallback(() => {
    const scrollFraction = getScrollFraction();
    const index = Math.min(
      numFrames - 1,
      Math.ceil(scrollFraction * numFrames),
    );
    // console.log('currIndex', index);

    if (type2) {
      if (scrollFraction > 1) {
        setFrameIndex(numFrames);
        return;
      } else if (scrollFraction < 0) {
        setFrameIndex(0);
        return;
      }
    }
    if (index <= 0 || index > numFrames) {
      return;
    }
    setFrameIndex(index);
  }, [numFrames, getScrollFraction, type2]);

  const renderCanvas = useCallback(() => {
    const context = canvasRef.current!.getContext('2d');
    context!.canvas.width = width;
    context!.canvas.height = height;
  }, [height, width]);

  useEffect(() => {
    preloadImages();
    renderCanvas();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, preloadImages, renderCanvas]);

  useEffect(() => {
    if (!canvasRef.current || images.length < 1) {
      return;
    }

    const context = canvasRef.current.getContext('2d');
    let requestId: number;

    const render = () => {
      context!.fillStyle = 'black';
      context!.fillRect(
        0,
        0,
        canvasRef.current!.width,
        canvasRef.current!.height,
      );
      let imagesArr = type2 ? [...images] : [...images];

      context!.drawImage(imagesArr[frameIndex], 0, 0);
      requestId = requestAnimationFrame(render);
    };

    render();

    return () => cancelAnimationFrame(requestId);
  }, [frameIndex, images, type2, height, width]);

  const style = useHeightOnCanvas ? { height: scrollHeight } : {};

  const Canvas = (
    <div style={type2 ? {} : style}>
      <canvas ref={canvasRef} />
    </div>
  );
  return [Canvas];
};

export default useKineograph;
